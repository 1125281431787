import { Component, computed, input, numberAttribute } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-spinner',

  imports: [CommonModule],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  public size = input('5rem')
  public className = input('')
  public borderWidth = input(8, {
    transform: numberAttribute,
  })
  protected style = computed(() => ({
    height: this.size(),
    width: this.size(),
    borderWidth: this.borderWidth() + 'px',
  }))
}
